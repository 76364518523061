<template>
  <div class="bba-bg" />
  <div class="bg-overlay">
    <div class="form-card">
      <div class="logo">
        <img
          src="../assets/images/login_ccs_logo.png"
          alt="logo"
        >
        <h1>Login</h1>
      </div>
        
      <Form
        ref="form"
        :validation-schema="schema"
        @submit="onSubmit"
      >
        <div class="input-group">
          <Field
            v-model="userName"
            placeholder="Email"
            name="userName"
            type="text"
          />
        </div>
        <ErrorMessage name="userName" /> 
      
        <div class="input-group">
          <Field
            v-model="password"
            placeholder="Password"
            name="password"
            :type="showPassword ? 'text' : 'password'"
          />
          <div
            class="toggle"
            @click="togglePassword"
          >
            <fa :icon="showPassword ? 'eye' : 'eye-slash'" />
          </div>
        </div>
        <ErrorMessage name="password" /> 

        <button type="submit">
          <fa
            v-show="spinner===true"
            icon="spinner"
            spin
          />  Submit
        </button>    
      </Form>
    </div> 
  </div>
</template>


<script setup>
// @ is an alias to /src
import { useRoute,useRouter } from 'vue-router';
import {ref,onMounted} from 'vue'
import { Form, Field, ErrorMessage} from 'vee-validate';
import {login} from '@/service/helper-service.js';
import * as yup from 'yup';
import axios from 'axios';
import { useStore } from 'vuex';
const store = useStore(); 


const router = useRouter();

const form =ref(null);  
const userName = ref('');
const password = ref('');

const showPassword = ref(false);

const snackbarColor =ref('success');
const	timeout= ref(2000);
const snackbar= ref(false);
const text= ref('');
const spinner = ref(false);

const schema = yup.object({
	userName:yup.string().required('Please enter username'),
	password: yup.string('').required('Please enter password')
});

const togglePassword = ()=>{
	showPassword.value = !showPassword.value;
}

const onSubmit = (data) => {
	console.log('data',data);

	handleUserLogin(data.userName,data.password)



};




const handleUserLogin = async(name,password) => {
	spinner.value = true;
	try {
		const response =  await login('/admin/login',{"userName":name,
			"password":password,
			"type":"userpwd"});
 
		if(response.status === 200){
			console.log(response);
		  store.commit('authentication/setToken', response?.data?.data?.token);
			store.commit('authentication/setName', response?.data?.data?.data?.userName);
			store.commit('authentication/setID', response?.data?.data?.data?.userId);
     
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Logged in Successfully',
				color:'success',
				timeout:2000});


			form.value.resetForm();
			router.push({ path: 'members' });
		}

	}finally {
		spinner.value = false; // Hide spinner
	}
	
}


</script>

<style scoped>
  input:focus {
    outline: none; /* Remove the default blue outline */
    box-shadow: none; /* Remove any default box shadow */
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #e5e5e5 inset; /* Change the color here */
}
input:-moz-autofill {
  box-shadow: 0 0 0px 1000px #e5e5e5 inset; /* Change the color here */
}

   .bba-bg{
    position:absolute;
    background-image: url(../assets/images/login-bg.jpeg);
    background-size: cover;

    background-repeat: no-repeat;
    width:100%;
    height:100vh;}
    .bg-overlay{

       position: absolute;
       position: absolute;
    background: #fffffe49;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    backdrop-filter: blur(3px);
    display:flex;
    justify-content: center;
    align-items: center;
    }
    .form-card{
        box-shadow: 0px 10px 15px 0px #7C7C7C33;
    border: 1px solid #dddddd;
    border-radius: 12px;
    margin: 25px auto 25px 135px;
    background-color: #ffffff45;
    padding: 17px;
    width: 350px;
    backdrop-filter: blur(10px);
    }
    .form-card h1{color:#007cba;text-align:center;font-size:20px;}
    .logo img{width: 115px;
    margin: auto;
    display: block;}
    .input-group{
        display: flex;
        border: 1px solid #B2B2B2;
    border-radius: 5px;
    height: 38px;
    padding: 4px 13px;
    width: 100%;
    margin-top: 19px;
    }
    .form-card input{width:100%;}
    .form-card input:focus{border:unset;outline:unset;}

    .form-card button{background-color: #007cba;
    border-radius: 5px;
    height: 40px;
    padding: 4px 13px;
    width: 100%;
    margin-top: 19px;
    color: white;}
    .form-card span{font-size:14px;color:red;}
    .form-card .toggle{cursor: pointer;}

    @media only screen and (max-width: 600px) {
      .form-card{max-width:90%;}
}
</style>


