<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      class="sidebar-menu"
    >
      <div class="sidebar-logo">
        <img
          src="../../assets/images/ccs-logo.png"
          alt="Logo"
        >
      </div>
      <div class="sidbar-divider" />
      <!-- {{ store.state.authentication.features }} -->
      <ul>
        <li>
          <router-link to="/members">
            <fa icon="user" /> Members
          </router-link>
        </li>
        <!-- <li>
          <router-link to="/add-members">
            <fa icon="user-plus" /> Add Members
          </router-link>
        </li> -->
        <!-- <li>
          <router-link to="/broadcast">
            <fa icon="tower-broadcast" />Broadcast
          </router-link>
        </li> -->
        <!-- <li>
          <router-link to="/gallery">
            <fa icon="image" />Gallery
          </router-link>
        </li> -->
      </ul>

      
      <div
        class="logout"
        @click="logoutDialog=true"
      >
        <fa
          icon="arrow-right-from-bracket"
          style="margin-right: 10px;"
        /> Logout
      </div>  
    </v-navigation-drawer>

    <v-app-bar>
      <template #prepend>
        <v-app-bar-nav-icon @click="drawer = !drawer" />
      </template>

      <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->

      <v-app-bar-title>Admin Panel</v-app-bar-title>
      <template #append>
        <div class="profile_menu">
          {{ firstChar(store.state.authentication.name) }}
        </div>
      </template>
    </v-app-bar>

    <v-main>
      <slot />
    </v-main>
  </v-app>

  <!-- deactivate dialog -->
  <v-dialog 
    v-model="logoutDialog"
    width="500"
  >
    <v-card class="delete-dialog">
      <div
        class="close-icon"
        @click="logoutDialog = false"
      >
        <fa icon="close" />
      </div>
          
            
      <v-card-text>
        <h3> <fa icon="arrow-right-from-bracket" /> Are you sure?</h3>
    
        <p style="margin-top: 12px;">Are you sure you want to logout? </p>
      </v-card-text>
       
      <v-card-actions class="delete-action">
        <button @click="logoutDialog = false">
          Cancel
        </button>
        <button @click="logout">
          Logout
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- deactivate dialog -->
</template>



<script setup>
import {ref} from 'vue'
import { useRoute,useRouter } from 'vue-router';
import { useStore } from 'vuex';
const store = useStore(); 


let drawer = ref( null);
let logoutDialog = ref(false);

const logout = ()=>{
  logoutDialog.value =false;
	store.commit('authentication/logout',100);
	store.commit('snackbar/showSnackbar',{
		show:true,
		message:'Successfully logged out',
		color:'success',
		timeout:2000});
}
  const firstChar = (name)=>{
	return name.charAt(0).toUpperCase();
}
</script>

<style scoped>
.v-toolbar-title{text-align: left;}

.sidebar-logo img{width:120px;margin:auto;display: block;}
.sidebar-logo{position: relative;}


.sidebar-menu{background-color: #fff;
    width:300px;
    position: fixed;
    z-index: 1020;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 22px;
    transition: all 0.3s;
    border-radius: 0 22px 22px 0;
    border: 1px solid #c3bcbc;}

.sidbar-divider{width: 100%;
    height: 2px;
    opacity: 0.44;
    background: #CFCFCF;
    margin-top: 20px;
    margin-bottom: 42px;}

.sidebar-menu ul{list-style: none;padding: 0;}
.sidebar-menu ul li{line-height: 51px;
    text-align: left;
    }
 .sidebar-menu ul li .svg-inline--fa{margin-left: 18px;margin-right:13px;}
.sidebar-menu ul li a{    width: 100%;
    display: flex;
    text-decoration: none;
    color: black;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
    /* padding: 0px 2px; */
    height: 44px;
    align-items: center;}
.sidebar-menu ul li a:hover,.sidebar-menu ul li a.router-link-exact-active{border-radius: 13px;
background: #007CBA;color:#fff;transition: ease-in-out .2s;}
 .show-sidebar{transform: translateX(0%);}
 .hide-sidebar{transform: translateX(-100%);}

/* admin container */
.dashboard-container{width: 100%;}
.dashboard-content{padding: 12px;}
.v-app-bar{display: flex;align-items: center;box-shadow:rgb(0 0 0 / 15%) 8px -2px 16px 0px!important;
 width:100%;color:black;}

 
 .nav-action{margin-right: auto;
    display: flex;
    align-items: center;
    padding: 0 12px;
    margin-left: 18px;}
    .nav-action .svg-inline--fa{margin-right: 12px;cursor: pointer;}
    .profile_menu{background-color: #007CBA;
    color: #fff;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 18px;}
 .show-dashboard-container{max-width:calc(100% - 295px);margin-left: auto;display: block;}
 .hide-dashboard-container{width: 100%;}

 .logout{position: absolute;
    bottom: 38px;
    margin: 20px;
    min-width: 100%;
    display: block;
    cursor: pointer;
    font-weight: 800;}

    .delete-dialog {position: relative!important;border-radius: 12px!important;}
  .delete-dialog input{ border: 1px solid gray;
      border-radius: 4px;
      height: 36px;
      margin: 12px 24px 0;
      background: white;}
   .delete-dialog .err-msg{margin-left: 24px;
      color: red;
      font-size: 14px;}   
  .delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}

  .v-card-text h3{display: flex;align-items: center;}
  .v-card-text p{font-weight: 600;}
  .v-card-text .fa-arrow-right-from-bracket{color: #007CBA;
      background-color: #cae8ff;
      padding: 9px;
      border-radius: 50%;
      font-size: 15px;
      margin-right: 12px;}

  .delete-action{padding: 5px 21px 18px;display: flex;justify-content: flex-end;}
  .delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
  .delete-action button:nth-child(2) {background-color: #007CBA;color: white;border-color:#007CBA;}
  .delete-action button{padding: 6px 13px;
      border-radius: 9px;
      border: 1px solid;}
  li a .svg-inline--fa{width: 20px;} 
 
</style>