<template>
  <div style="position: fixed;margin:auto;display: block;width: 100%;">
    <AdminPanel>
      <v-container>
        <div class="card-container">
          <div
            class="card"
            :class="{active:selectedInfoTab ==='TotalMembers'}"
            @click="selectInfoCard('TotalMembers')"
          >
            <div class="card-group">
              <fa icon="circle-check" />
              <div class="count">
                {{ totalMembers }}
              </div>
            </div>
            <div class="card-name">
              Total Members
            </div>
          </div>

          <div
            class="card"
            :class="{active:selectedInfoTab ==='ActiveMembers'}"
            @click="selectInfoCard('ActiveMembers')"
          >
            <div class="card-group">
              <fa icon="person-running" />
              <div class="count">
                {{ activeMembers }}
              </div>
            </div>
            <div class="card-name">
              Active Members
            </div>
          </div>

          <div
            class="card"
            :class="{active:selectedInfoTab ==='InactiveMembers'}"
            @click="selectInfoCard('InactiveMembers')"
          >
            <div class="card-group">
              <fa icon="user-times" />
              <div class="count">
                {{ inactiveMembers }}
              </div>
            </div>
            <div class="card-name">
              Inactive Members
            </div>
          </div>

          <div
            class="card"
            :class="{active:selectedInfoTab ==='PendingRegistration'}"
            @click="selectInfoCard('PendingRegistration')"
          >
            <div class="card-group">
              <fa icon="clock" />
              <div class="count">
                {{ pendingRegistration }}
              </div>
            </div>
            <div class="card-name">
              Pending Registration
            </div>
          </div>
 
          <!-- <div
            class="card"
            :class="{active:selectedInfoTab ==='RegisteredMembers'}"
            @click="selectInfoCard('RegisteredMembers')"
          >
            <div class="card-group">
              <fa icon="circle-user" />
              <div class="count">
                {{ registeredMembers }}
              </div>
            </div>
            <div class="card-name">
              Registered Members
            </div>
          </div> -->

          <div
            class="card"
            :class="{active:selectedInfoTab ==='PendingApproval'}"
            @click="selectInfoCard('PendingApproval')"
          >
            <div class="card-group">
              <fa icon="list-check" />
              <div class="count">
                {{ pendingApproval }}
              </div>
            </div>
            <div class="card-name">
              Pending Approval
            </div>
          </div>

          <!-- <div
            class="card"
            :class="{active:selectedInfoTab ==='ApprovedMembers'}"
            @click="selectInfoCard('ApprovedMembers')"
          >
            <div class="card-group">
              <fa icon="circle-check" />
              <div class="count">
                {{ registeredMembers }}
              </div>
            </div>
            <div class="card-name">
              Approved Members
            </div>
          </div> -->
        </div>

        <div>
          <MembersList
            v-if="selectedInfoTab ==='TotalMembers'"
            :type="'all'"
          />
          <MembersList
            v-if="selectedInfoTab ==='ActiveMembers'"
            :type="'completed'"
          /> 
          <MembersList
            v-if="selectedInfoTab ==='InactiveMembers'"
            :type="'inactive'"
          /> 
          <MembersList
            v-if="selectedInfoTab ==='PendingRegistration'"
            :type="'pending'"
          /> 
          <MembersList
            v-if="selectedInfoTab ==='RegisteredMembers'"
            :type="'completed'"
          /> 
          <MembersList
            v-if="selectedInfoTab ==='PendingApproval'"
            :type="'pendingApproval'"
          />
        </div>
      </v-container>
    </AdminPanel>
  </div>
</template>


<script setup>
// @ is an alias to /src
import AdminPanel from '@/components/ui/AdminPanel.vue'
import MembersList from '@/components/members/MembersList.vue'
import {getMemberCount} from '@/service/helper-service.js';
import {ref,onMounted,computed} from 'vue'
import {useStore} from 'vuex';


const store = useStore();
const selectedInfoTab = ref('TotalMembers');


const totalMembers = computed(()=>{
	return store.state.members.totalMembers;
})

const activeMembers = computed(()=>{
	return store.state.members.activeMembers;
})

const inactiveMembers = computed(()=>{
	return store.state.members.inactiveMembers;
})

const pendingRegistration = computed(()=>{
	return store.state.members.pendingRegistration;
})

const pendingApproval = computed(()=>{
	return store.state.members.pendingApproval;
})


onMounted(()=>{
	loadMemberCount();
})

const loadMemberCount = async()=>{
	try {
  
		const response = await getMemberCount(`/admin/users/count`);
		const membersCounts = response?.data?.data;
		console.log('membersCounts',membersCounts);
		store.state.members.totalMembers = membersCounts?.allUsers;
		store.state.members.activeMembers = membersCounts?.completed;
		store.state.members.inactiveMembers = membersCounts?.inactiveUsers;
		store.state.members.pendingRegistration = membersCounts?.pending;
		store.state.members.pendingApproval =  membersCounts?.pendingApproved;
	} catch (error) {
		console.log(error);
	}
}

const selectInfoCard = (tabName) =>{
	selectedInfoTab.value = tabName;
           
}



</script>

<style scoped>
  .card-container{display: flex;justify-content: space-evenly;margin-top: -5px;}
   .card{border-radius: 6px;
    border: 1px solid #d3d2d2;
    background-color: #f7f6f6;
    padding: 16px 15px;
    display: flex;
    flex-direction: column;
    /* flex-grow: 1; */
    min-width: 80px;
    width: 100%;
    margin-left: 13px;
    cursor: pointer;
    
    }
    .card:nth-child(1){margin-left: unset;}
  .card:hover,.card.active{background-color: #007CBA;color:#fff;transition: ease-in-out .8s;}
  .card-group{display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;}
 
.card-name{font-weight: 600;margin-top: 12px;font-size: 14px;text-align: left;}
.card .svg-inline--fa{padding: 8px;border-radius: 50%;font-size: 21px;width: 21px;height: 21px;}
.card:nth-child(1) .svg-inline--fa{color: #8BC34A;background-color: #ddf2c4;}
.card:nth-child(2) .svg-inline--fa{color: #8BC34A;background-color: #ddf2c4;}
.card:nth-child(3) .svg-inline--fa{color: #FF0000;background-color: #fdd7d7;}
.card:nth-child(4) .svg-inline--fa{color:#F2881D;background-color: #EFE3D7;}
.card:nth-child(5) .svg-inline--fa{color: #8BC34A;background-color: #ddf2c4;}
.card:nth-child(6) .svg-inline--fa{color:#F2881D;background-color: #EFE3D7;}
.card:nth-child(7) .svg-inline--fa{color: #8BC34A;background-color: #ddf2c4;}
</style>


