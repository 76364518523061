<template>
  <DetailDrawer
    :data="memberView.data"
    :full-name="memberView.fullName"
    @activate-member-confirmation="activateMemberConfirmation"
    @deactivate-member-confirmation="deactivateMemberConfirmation"
  />
  <div class="tab-heading-and-action">
    <h3>{{ listHeading(type) }}</h3>
    <div class="filter">
      <div class="search-container">
        <fa icon="search" />
        <input
          v-model="name"
          type="text"
          placeholder="Search"
          @input="searchMember"
        >
        <fa
          v-show="name!=''"
          icon="xmark"
          class="pointer"
          @click="name=''"
        />
      </div>
    </div>
  </div>
 
  <v-data-table-server
    v-model:items-per-page="itemsPerPage"
    :height="tableHeight"
    fixed-header
    :search="search"
    :headers="headers"
    :items-length="totalItems"
    :items="serverItems"
    :loading="loading"
    item-value="name"
    @update:options="loadItems"
  >
    <template #[`item.action`]="{ item }">
      <fa
        icon="eye"
        class="pointer"
        @click="viewMember(item.view)"
      />
    </template>

    <template #[`item.email_id`]="{ item }">
      <v-tooltip :text="item.email_id">
        <template #activator="{ props }">
          <div
            v-if="item.email_id!=''"
            v-bind="props"
            class="pointer"
          >
            {{ truncateString(item.email_id,10) }}
          </div>
        
          <div v-else>
            -
          </div>
        </template>
      </v-tooltip>
    </template>  

   

    <template #[`item.status`]="{ item }">
      <div :class="item.status">
        {{ capitalizeFirstLetter(item.status=='pendingApproval'?'Pending Approval':item.status=='deactivated'?'Inactive':item.status) }}
      </div>
    </template>
  </v-data-table-server>


 
  <div class="text-center">
    <!-- <v-btn
      color="primary"
      @click="deleteDialog = true"
    >
      Open Dialog
    </v-btn> -->

    <v-dialog 
      v-model="deactivateDialog"
      width="500"
    >
      <v-card class="delete-dialog">
        <div
          class="close-icon"
          @click="deactivateDialog = false"
        >
          <fa icon="close" />
        </div>
        
          
        <v-card-text>
          <h3> <fa icon="trash-can" /> Are you sure?</h3>
          Are you sure you want to deactivate this member? This action 
          cannot be undone.
        </v-card-text>
     
        <v-card-actions class="delete-action">
          <button @click="deactivateDialog = false">
            Cancel
          </button>
          <button 
            :disabled="deactivating?true:false"
            @click="deactivateMember"
          >
            Deactivate
            <fa
              v-show="spinner===true"
              icon="spinner"
              spin
            /> 
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>

      
    <!-- activate dialog -->
    <v-dialog 
      v-model="activateDialog"
      width="500"
    >
      <v-card
        class="delete-dialog activate-dialog"
        style="background-color: #fff;"
      >
        <div
          class="close-icon"
          @click="activateDialog = false"
        >
          <fa icon="close" />
        </div>
          
            
        <v-card-text>
          <h3> <fa icon="check" /> Are you sure?</h3>
          Are you sure you want to activate this member? This action cannot be undone. 
        </v-card-text>
       
        <v-card-actions class="delete-action ">
          <button @click="activateDialog = false">
            Cancel
          </button>
          <button
            class="activate-flat-button"
            :disabled="activating?true:false"
            @click="activateMember"
          >
            Activate
            <fa
              v-show="spinner===true"
              icon="spinner"
              spin
            /> 
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- activate dialog -->
  </div>
</template>





<script setup>
import { ref, watch, provide, reactive, onBeforeMount } from 'vue';
import axios from 'axios';
import moment from 'moment';
// import ViewMember from './ViewMember.vue';
import DetailDrawer from '@/components/members/DetailDrawer.vue';
import { useStore } from 'vuex';
import { truncateString, formattedDate,listHeading, capitalizeFirstLetter} from '@/helper/index.js';
import {getMemberList,activateUser,deactivateUser,getMemberCount} from '@/service/helper-service.js';

const store = useStore();   
const props = defineProps({
	type:{
		type:String,
		default:''
	}
})


let openMemberDetail = reactive({'status':false,'id':''});
provide('openMemberDetail', openMemberDetail)

const itemsPerPage = ref(5);
const headers = ref([
	{ title: 'Full Name', value: 'full_name' },
	// { title: 'Last Name', value: 'last_name' },
	{ title: 'Phone Number', value: 'phone_number' },
	{ title: 'Email ID', value: 'email_id' },
	{ title: 'Created Date', value: 'created_date' },
	{ title: 'Last Updated', value: 'last_updated' },
	// { title: 'Chapter', value: 'chapter' },
	{ title: 'Status', value: 'status' },
	{ title: 'Action', value: 'action' }
]);

const offset  = ref(0);

const serverItems = ref([]);
const loading = ref(true);
const totalItems = ref(0);
const memberView = reactive({
	imageUrl:require(`@/assets/images/avatar.svg`),
	imageName:'',
	fullName:'',
	data:{}
})
const searchBy = ref('Name');
const name = ref(''); 
const search = ref('');
const deactivateDialog = ref(false);
const activateDialog = ref(false);
const deleteDialog = ref(false);
const viewDialog = ref(false);
const timeout =  ref(2000);
const testId = ref('');
const filters = ref({
	businessCategory: '',
	gender: ''
});
const showFilter = ref(false);
const tableHeight = ref(0);
const memberId = ref('');
const dialCode = ref('');
const phoneNumber = ref('');
const activating = ref(false);
const deactivating = ref(false);
const spinner = ref(false);







onBeforeMount(() => {
	if (window.innerHeight <= 657) {
		itemsPerPage.value = 8;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 730) {
		itemsPerPage.value = 12;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 80% */
	if (window.innerHeight <= 821) {
		itemsPerPage.value = 13;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 75% */
	if (window.innerHeight <= 876) {
		itemsPerPage.value = 17;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 67% */
	if (window.innerHeight <= 986) {
		itemsPerPage.value = 21;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 50% */
	if (window.innerHeight <= 1314) {
		itemsPerPage.value = 31;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 33.3% */
	if (window.innerHeight <= 1971) {
		itemsPerPage.value = 41;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
	/* 25% */
	if (window.innerHeight <= 2628) {
		itemsPerPage.value = 48;
		tableHeight.value = window.innerHeight - 300;
		return;
	}
})

watch(name, () => {
	clearTimeout(timeout.value);
	timeout.value= setTimeout(()=> {
		search.value = String(Date.now())
	},1000)
});




          


const loadItems = async({ page, itemsPerPage, sortBy })=>{
	console.log("token",localStorage.getItem('token'))
	offset.value = (page - 1) * itemsPerPage
	loading.value = true

	try {
		const response = await getMemberList(`/users/list?type=${props.type}&limit=${itemsPerPage}&offset=${offset.value}`,{
			value:name.value});

		console.log('response.data',response.data);
   
		totalItems.value = response?.data?.data.totalCount;
		loading.value = false;

		let memberData = response?.data?.data?.data;
		serverItems.value =  memberData.map(item => ({
			full_name: fullNameGenerator(item),
			last_name: item.lastName || '-',
			gender: item.gender || '',
			dob: item.dateOfBirth || '',
			id: item.userId,
			phone_number: `${item.phone?.countryCode || ''} ${item.phone?.number || 'N/A'}`,
			email_id: item.email || '',
			created_date: formattedDate(item.createdAt),
			last_updated: relatedTime(item.updatedAt),
			// chapter: item.chapter || '',
			status: item.userStatus,
			action: item._id,
			view: item,
		}))

	} catch (error) {
		console.error('MEMBER LIST:', error);
		
	}

}

const fullNameGenerator = (data)=>{

	let fullName ='';
	if(data?.firstName){
		fullName+= data?.firstName;
	}

	if(data?.middleName){
		fullName+=' '+data?.middleName;
	}

	if(data?.lastName){
		fullName+=' '+data?.lastName;
	}
	return fullName;
}

const viewMember = (data) => {
	console.log("view data",data);
	memberView.data = data;
	if(data?.title){
		memberView.fullName = data?.title;
	}

	if(data?.firstName){
		memberView.fullName+= ' '+data?.firstName;
	}

	if(data?.middleName){
		memberView.fullName+=' '+data?.middleName;
	}

	if(data?.lastName){
		memberView.fullName+=' '+data?.lastName;
	}
	openMemberDetail.status = true;

	
}

const activateMemberConfirmation = (item) =>{
	memberId.value = item.userId;
	dialCode.value = item.phone.countryCode;
	phoneNumber.value = item.phone.number;
	activateDialog.value =true;
}

const deactivateMemberConfirmation = (item) =>{
	memberId.value = item.userId;
	dialCode.value = item.phone.countryCode;
	phoneNumber.value = item.phone.number;
	deactivateDialog.value =true;
}

const deactivateMember = async() => {
	spinner.value = true;
	try {
		const response =  await deactivateUser('/users',{
			"userId":memberId.value,
			"data":{
				"phone": {
					"countryCode": dialCode.value,
					"number":phoneNumber.value
				},
				"userStatus":"deactivated"}
		});
 
		if(response.status === 200){
			deactivateDialog.value=false;
			openMemberDetail.status = false;
            
			// loading = true
			search.value = String(Date.now())
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Deactivated Successfully',
				color:'success',
				timeout:2000});
			loadMemberCount();	
				
		}

	}catch (error) {
		
	}finally {
		spinner.value = false; // Hide spinner
	}
	
}



const activateMember = async() => {
	spinner.value = true;
	try {
		const response =  await activateUser('/users',{
			"userId":memberId.value,
			"data":{
				"phone": {
					"countryCode": dialCode.value,
					"number":phoneNumber.value
				},
				"userStatus":"active"}
		});
 
		if(response.status === 200){
			
			activateDialog.value=false;
			openMemberDetail.status = false;
            
			// loading = true
			search.value = String(Date.now())
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:'Activated Successfully',
				color:'success',
				timeout:2000});
			loadMemberCount();
				
		}

	}catch (error) {
		
		
	}finally {
		spinner.value = false; // Hide spinner
	}
	
}

const relatedTime = (data) => {
	let localTime = moment.utc(data).toDate();
	localTime = moment(localTime).format('YYYY-MM-DD HH:mm:ss');
	let difference = moment(localTime).fromNow(); 
	return difference;
};



const loadMemberCount = async()=>{
	try {
  
		const response = await getMemberCount(`/admin/users/count`);
		const membersCounts = response?.data?.data;
		console.log('membersCounts',membersCounts);
		store.state.members.totalMembers = membersCounts?.allUsers;
		store.state.members.activeMembers = membersCounts?.completed;
		store.state.members.inactiveMembers = membersCounts?.inactiveUsers;
		store.state.members.pendingRegistration = membersCounts?.pending;
		store.state.members.pendingApproval =  membersCounts?.pendingApproved;
	} catch (error) {
		console.log(error);
	}
}



</script>

<style scoped>
.tab-heading-and-action{display: flex;margin-top: 12px;margin-bottom: 13px;align-items: baseline;}
.tab-heading-and-action h3{font-size: 18px;}
.tab-heading-and-action .fa-filter{color: gray;margin-left: 12px;}
.search-container{border-radius: 8px;
      border: 1px solid #E7E7E7;
      background: #F5F5F5;
      padding: 4px 5px;}
   .search-container .fa-magnifying-glass{color:#939393;margin-right: 8px;}  
   .search-container input{outline: unset;}
   .filter{position: relative;width: max-content;margin-left: auto;display: flex;align-items: center;}
  .filter-btn{position: relative;cursor: pointer;}
  .filtercard{position: absolute;top:30px;right:0px;width: 250px; background-color: #fff; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;padding: 12px;display: flex;flex-direction: column;z-index: 1020;}
  .filtercard select{border: 1px solid #B2B2B2;
      padding: 6px;
      margin-bottom: 12px;
      border-radius: 5px;
      cursor: pointer;
      outline: unset;
      color:#B2B2B2;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAHCAYAAAD9NeaIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgKE1hY2ludG9zaCkiIHhtcDpDcmVhdGVEYXRlPSIyMDE1LTA0LTE3VDE3OjEyOjQyKzAyOjAwIiB4bXA6TW9kaWZ5RGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgeG1wOk1ldGFkYXRhRGF0ZT0iMjAxNS0wNC0yMFQxNzoxNjoyNCswMjowMCIgZGM6Zm9ybWF0PSJpbWFnZS9wbmciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTU4MjBDRURERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTU4MjBDRUVERjVCMTFFNEEzN0FCODBEM0I5MTExMjkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2RUVFRDJCNkREQzMxMUU0QTM3QUI4MEQzQjkxMTEyOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNTgyMENFQ0RGNUIxMUU0QTM3QUI4MEQzQjkxMTEyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuShL/sAAABeSURBVHjaYszOzjZnYGDYCcT8DMSBv0AcP2XKlKVEqmdgAuKTQOwOxB+JtQCIibYAZgkDkRaRZQGyJYQsItsCdEtwWUSRBdgsQbeIYgtAgAWHOMwiJSBezkAhAAgwAJSTG/DI0S9VAAAAAElFTkSuQmCC);
      background-position: 100% 60%;
      background-repeat: no-repeat;
      background-size: auto 15%;
  }
.filter-action{display:flex;align-items:center;margin-bottom: 15px;}   
.reset-btn{color:#007CBA;font-weight: 600;font-size: 14px;margin-left: 12px;cursor:pointer}
.filter-close-btn{width: max-content;
    margin-left: auto;
    display: block;
   cursor: pointer;}

.input-group{border-radius: 8px;
    border: 1px solid #E7E7E7;
    background: #F5F5F5;
    padding: 4px 5px;}
 .input-group .fa-magnifying-glass{color:#7E7E7E;margin-right: 8px;}  
 .input-group input{outline: unset;}

.v-table :deep() thead{ background-color: #65A4C4;color:#fff;}
/* .v-table :deep() thead tr > th{width: 120px;} */
.v-table :deep() tbody tr > td{text-align: left;}
.v-table :deep() tbody tr:nth-child(even){background-color: #ECF2F6;border-top:unset!important;}
.v-table :deep() tbody tr:nth-child(odd) > td{border-bottom:1px solid #fff;}
.v-table :deep() tbody tr:nth-child(even) > td{border-bottom:1px solid #ECF2F6;}
.v-table :deep() tbody tr > td .fa-eye{color:#007CBA;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-bell{color: #353535;margin-right: 5px;}
.v-table :deep() tbody tr > td .fa-circle-xmark{color:#EC1B1B;}
.v-table :deep() tbody tr > td > a .fa-pen-to-square{color:black;margin-right: 5px;}
.pointer{cursor: pointer;}

.delete-dialog {position: relative!important; background-color: #FFF9F9;border-radius: 12px!important;}
.delete-dialog .close-icon{color: gray;width:max-content; position: absolute;right: 20px;top: 14px;cursor: pointer;}
.v-card-text h3{display: flex;align-items: center;}
.v-card-text .fa-trash-can{color: #EC1B1B;
    background-color: #FEE4E2;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;}
.delete-action{padding: 9px 21px;display: flex;justify-content: flex-end;}
.delete-action button:nth-child(1) {background-color: white;color: black;margin-right: 12px;border-color: #989898;}
.delete-action button:nth-child(2) {background-color: #D92D20;color: white;border-color:#D92D20;}
.delete-action button{padding: 6px 13px;
    border-radius: 9px;
    border: 1px solid;}

 .view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;z-index: 20000;width:100%;height: 100vh;}
 .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
.view-modal-action{display: flex;align-items: center;margin-top: 14px;margin-bottom: 14px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 18px;}
.view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:scroll;}
.member-details-content{position:sticky;text-align: left;}
.member-details-content h5{color: #858585;font-weight: 500;
    font-size: 16px;}
.member-details-content p{color: #242426;font-weight: 600;
    margin-bottom: 23px;}
 .member-details-content button{
    border: 1px solid #EC1B1B;
    color: #EC1B1B;
    padding: 8px 10px;
    display: block;
    margin: 0 auto 12px;
    border-radius: 5px;
    font-weight: 600;
    min-width: 200px;}
    .active,.deactivated,.pending,.pendingApproval{padding: 5px 6px;
      text-align: center;
      font-weight: 800;
      border-radius: 5px;
      border:1px solid #C2C2C2;}   
    .active{background-color: #CFFFD7;} 
    .deactivated{background-color: #FFCFCF;}  
    .pending,.pendingApproval{background-color: #f5e65e;}    

	.activate-flat-button{
		background-color: #8BC34A!important;color: white;border-color: #8BC34A!important;
	}  

	.v-card-text .fa-check{
    color: #8BC34A;
    background-color: #CFFFD7;
    padding: 9px;
    border-radius: 50%;
    font-size: 15px;
    margin-right: 12px;
    }   
</style>

