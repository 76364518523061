import { createStore } from 'vuex'
import router from '@/router';

const authentication = {
	namespaced: true,
	state: () => ({
		token:localStorage.getItem('token') || '',
		name:localStorage.getItem('name') || '',
		id:localStorage.getItem('id') || '',
		phoneLogin:true,
	  phoneNumber:localStorage.getItem('phoneNumber') || ''}),
		
		
	mutations: { 
		setName(state,name){
			state.name = name;
			localStorage.setItem('name',name); 
		},
		setToken (state, token) {
			state.token = token;
			localStorage.setItem('token',token) },
		setID (state, id) {
			state.id = id;
			localStorage.setItem('id',id) },
		setDialCode (state, dialCode) {
			state.dialCode = dialCode;
			localStorage.setItem('dialCode',dialCode) },		
		setPhoneNumber (state, phoneNumber) {
			state.phoneNumber = phoneNumber;
			localStorage.setItem('phoneNumber',phoneNumber) },	
		setFeatures (state, object) {
			state.features = object;
			localStorage.setItem('features',object) },		
		clearToken (state) {
			state.token = '';
			localStorage.removeItem('token');},
		logout(state,payload) {
			localStorage.removeItem('token');
			localStorage.removeItem('name');
			localStorage.removeItem('dialcode');
			localStorage.removeItem('phoneNumber');
			localStorage.removeItem('features');
			state.token = '';
			state.name = '';
			state.dialcode = '';
			state.phoneNumber = '';
			state.features = {};
			setTimeout(()=>{
				router.push('/')
			},payload)
			
		},	
	},
	actions: {  
		// login(context, token) {
		// 	context.commit('setToken', token);},
		// logout(context) {
		// 	context.commit('clearToken');}
	},
	getters: {  }
}

const members = {
	state: () => ({
		totalMembers:0,
		activeMembers:0,
		inactiveMembers:0,
		pendingRegistration:0,
		pendingApproval:0}),
	mutations: {  },
	actions: {  },
	getters: {  }
}



const snackbar = {
	namespaced: true,
	state: () => ({
		show:false,
		timeout:2000,
		color:'success',
	    message:''}),
	mutations: { 
		showSnackbar(state,payload){
			state.show = payload.show;
			state.message = payload.message;
			state.color = payload.color;
			state.timeout = payload.timeout;
			
		}
	 },
	actions: {  },
	getters: {  }
}


const store =  createStore({
	
	modules:{
		authentication:authentication,
		members:members,
		snackbar:snackbar
	}
});




export default store;