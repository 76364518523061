import axios from 'axios';
import store from '../store'

 
// Create an axios instance
const apiClient = axios.create({
	baseURL: process.env.VUE_APP_SERVICE_URL, // Base URL of your API
	headers: {
		'Content-Type': 'application/json'
	},
	timeout: 10000, // Request timeout
});

// Add a request interceptor
apiClient.interceptors.request.use(
	config => {
		// Do something before request is sent
		const token = localStorage.getItem('token'); // or any other storage you're using

		if (token) {
			config.headers.Authorization = token;
		}
		return config;
	},
	error => {
		// Do something with request error
		
		return Promise.reject(error);
	}
);

// Add a response interceptor
apiClient.interceptors.response.use(
	response => {
		// Do something with response data
		return response;
	},
	error => {
		if (error.response) {
			if(error.response.data.message){

				if(error.response.data.message ==='jwt expired'){
					store.commit('snackbar/showSnackbar',{
						show:true,
						message:'Token expired',
						color:'danger',
						timeout:2000});
					store.commit('authentication/logout',2000);
				}

				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.data.message,
					color:'danger',
					timeout:2000});
							

			}else{
				store.commit('snackbar/showSnackbar',{
					show:true,
					message:error.response.statusText,
					color:'danger',
					timeout:2000});
			}
		}else {
			store.commit('snackbar/showSnackbar',{
				show:true,
				message:error.message,
				color:'danger',
				timeout:2000});
		}
		
		return Promise.reject(error);
	}
);

export default apiClient;
