import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import LoginView from '../views/LoginView.vue'
import MembersView from '../views/MembersView.vue'
import AddMembersView from '../views/AddMembersView.vue'
import EditMemberView from '../views/EditMemberView.vue'
import GalleryView from '../views/GalleryView.vue'
import BroadcastView from '../views/BroadcastView.vue'
import CreateBroadcastView from '../views/CreateBroadcastView.vue'
import EditBroadcastView from '../views/EditBroadcastView.vue'

const routes = [
	{
		path: '/',
		name: 'login',
		component:LoginView,
		meta:{
			title:'Global directory -  Login',
			auth:false
		}
	},
	{
		path: '/members',
		name: 'members',
		component:MembersView,
		meta:{
			title:'Global directory -  Members',
			auth:true
		}
	},
	{
		path: '/add-members',
		name: 'add-members',
		component:AddMembersView,
		meta:{
			title:'Global directory -  Add Member',
			auth:true
		}
	},
	{
		path: '/edit-member/:id',
		name: 'EditMember',
		component: EditMemberView,
		meta:{
			title:'Global directory -  Edit Member',
			auth:true
		}
	},
	{
		path: '/gallery',
		name: 'gallery',
		component:GalleryView,
		meta:{
			title:'Global directory -  Gallery',
			auth:true
		}
	},
	{
		path: '/broadcast',
		name: 'broadcast',
		component:BroadcastView,
		meta:{
			title:'Global directory -  broadcast',
			auth:true
		}
	},
  {
		path: '/create-broadcast',
		name: 'createBroadcast',
		component:CreateBroadcastView,
		meta:{
			title:'Global directory - Create Broadcast',
			auth:true
		}
	},
  {
		path: '/edit-broadcast/:id',
		name: 'editBroadcast',
		component:EditBroadcastView,
		meta:{
			title:'Global directory - Edit Broadcast',
			auth:true
		}
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})


router.beforeEach((to, from, next)=>{
	console.log('to',to);
	console.log('from',from);
	//    console.log('next',next());
	
	if('auth' in to.meta && to.meta.auth && !store.state.authentication.token){
		document.title = `${to.meta.title}`;
		next('/');
	}else if('auth' in to.meta && !to.meta.auth && store.state.authentication.token){
		document.title = `${to.meta.title}`;
		next('/members');
	}else{
		document.title = `${to.meta.title}`;
		next();
	}
})

export default router